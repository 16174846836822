import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Container from "../components/Container"
import Mailchimp from "../components/Mailchimp"
import SiteLink from "../components/SiteLink"
import Svg from "../components/Svg"
import { formatLink, formatResourceLinkHref, formatResourceLinkLabel } from "../helpers"

const SiteFooter = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          allDatoCmsGlobal {
            edges {
              node {
                resourcesLinks {
                  resourcesShortLabel
                  title
                  slug
                  slugPath
                }
                byLaws
                doctrines
                governance
              }
            }
          }
          allDatoCmsMinistry(sort: {order: ASC, fields: priority}) {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      `}
      render={data => {
        let allResourceLinks = data.allDatoCmsGlobal.edges[0].node.resourcesLinks
        let linkDoctrines    = data.allDatoCmsGlobal.edges[0].node.doctrines
        let linkByLaws       = data.allDatoCmsGlobal.edges[0].node.byLaws
        let linkGovernance   = data.allDatoCmsGlobal.edges[0].node.governance

        return (
          <footer className="hcc-footer" role="contentinfo" data-datocms-noindex>
            <Container className="hcc-grid vcard">
              <div className="hcc-footer-intro hcc-grid">
                <Svg type="logo-symbol"/>
                <h6 className="org">Hillside Community Church</h6>
                <div>
                  {/* <p>We exist to help people discover who Jesus really is, and live with, like, and for Him, now and&nbsp;forever.</p> */}
                  <Mailchimp/>
                </div>
                <div className="adr">
                  <div className="street-address">9915 Ray White Rd.</div>
                  <div><span className="locality">Ft. Worth</span>, <span className="region">TX</span> <span className="postal-code">76244</span></div>
                </div>
                <div>
                  <div className="tel">(817) 379-1052</div>
                  <div className="email">info@yourhillside.com</div>
                </div>
                <div>
                  <div>Sunday Services</div>
                  <div>9:15am &amp; 11:15am</div>
                </div>
                <div>
                  <div>Office Hours (Tue-Fri)</div>
                  <div>9:00am-4:00pm</div>
                </div>
              </div>
              <nav className="hcc-grid">
                <ul>
                  <li><h6>Resources</h6></li>
                  <li><SiteLink href={formatLink('', 't')}><span>Talks</span></SiteLink></li>
                  <li><SiteLink href="/about/"><span>About</span></SiteLink></li>
                  {allResourceLinks.map((dataLink, iLink) => {
                    return (
                      <li key={`footer-resource-link-${iLink}`}>
                        <SiteLink href={formatResourceLinkHref(dataLink)}>
                          <span>{formatResourceLinkLabel(dataLink)}</span>
                        </SiteLink>
                      </li>
                    )
                  })}
                  <li><SiteLink href="/give/"><span>Give</span></SiteLink></li>
                </ul>
                <ul>
                  <li><h6>Ministries</h6></li>
                  {data.allDatoCmsMinistry.edges.map(({node : ministry}) => {
                    return <li key={`footer-ministry-link-${ministry.slug}`}>
                             <SiteLink href={formatLink(ministry.slug, 'm')}>
                               <span>{ministry.name}</span>
                             </SiteLink>
                           </li>
                  })}
                </ul>
                <ul>
                  <li><h6>Connect</h6></li>
                  <li><SiteLink href="/plan-your-visit/"><span>Plan Your Visit</span></SiteLink></li>
                  <li><SiteLink href={formatLink('', 'go')}><span>Go:Classes</span></SiteLink></li>
                  {/*<li><SiteLink href="/go-classes/"><span>Go:Classes</span></SiteLink></li>*/}
                  <li><SiteLink href="/life-groups/"><span>Life Groups</span></SiteLink></li>
                  <li><SiteLink href="/studies/"><span>Studies</span></SiteLink></li>
                  <li><SiteLink href="/serve/"><span>Serve</span></SiteLink></li>
                  <li><SiteLink href={formatLink('', 'e')}><span>Events</span></SiteLink></li>
                </ul>
                <ul>
                  <li><h6>Social</h6></li>
                  <li><a href="https://www.facebook.com/Hillside-Community-Church-196235170404380/" target="_blank" rel="noopener noreferrer"><span>Facebook</span></a></li>
                  <li><a href="https://www.instagram.com/yourhillside/" target="_blank" rel="noopener noreferrer"><span>Instagram</span></a></li>
                  <li><a href="https://twitter.com/yourhillside" target="_blank" rel="noopener noreferrer"><span>Twitter</span></a></li>
                  <li><a href="https://www.youtube.com/channel/UCZL_z65ehWIRtr9I4mJlOoA" target="_blank" rel="noopener noreferrer"><span>YouTube</span></a></li>
                </ul>
              </nav>
            </Container>
            <div className="hcc-footer-full-stop">
              <Container>
                {!! linkDoctrines && <span><a href={linkDoctrines} target="_blank" rel="noopener noreferrer">Doctrines</a></span>}
                {!! linkByLaws && <span><a href={linkByLaws} target="_blank" rel="noopener noreferrer">By-Laws</a></span>}
                {!! linkGovernance && <span><a href={linkGovernance} target="_blank" rel="noopener noreferrer">Governance</a></span>}
              </Container>
            </div>
          </footer>
        )
      }}
    />
  )
}

export default SiteFooter
