import React from "react"
import PropTypes from "prop-types"

const Svg = ({type, ...props}) => {

  const ministryOpacity = 0.66

  const data = {

    'logo-symbol' : {
      width   : '216',
      height  : '216',
      viewbox : '0 0 216 216',
      markup  : <g><path d="M108,214.1C49.5,214.1,1.9,166.5,1.9,108C1.9,49.5,49.5,1.9,108,1.9S214.1,49.5,214.1,108C214.1,166.5,166.5,214.1,108,214.1z M108,16.5c-50.5,0-91.5,41.1-91.5,91.5c0,50.5,41.1,91.5,91.5,91.5c50.5,0,91.5-41.1,91.5-91.5C199.5,57.5,158.5,16.5,108,16.5z"/><path d="M122.4,76.3c-13.2,0-22.5,6-27.2,11.6V49.6L70.1,61v96h25.1v-50.9c2.9-3.6,7.8-7.7,15.2-7.7c8,0,13,3.1,13,12.7V157h25.1v-56.1C148.5,86.4,140.4,76.3,122.4,76.3z"/></g>,
    },
    'logo-wordmark' : {
      width   : '168',
      height  : '40',
      viewbox : '0 0 168 40',
      markup  : <g><path className="is-h" d="M18.1,23.7c0-3.3-1.7-4.3-4.4-4.3c-2.5,0-4.2,1.4-5.2,2.6v17.3H0v-35l8.5-2v13.5c1.6-1.9,4.8-3.9,9.3-3.9c6.1,0,8.9,3.4,8.9,8.4v19.1h-8.5V23.7z"/><path className="is-i-1" d="M30.9,4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9c0,2.7-2.2,4.9-4.9,4.9C33.1,9.8,30.9,7.6,30.9,4.9zM31.5,12.5h8.5v26.8h-8.5V12.5z"/><path className="is-l-1" d="M44.9,4.3l8.5-2v37h-8.5V4.3z"/><path className="is-l-2" d="M58.3,4.3l8.5-2v37h-8.5V4.3z"/><path className="is-s" d="M72.9,30c2,1.8,6.3,3.6,9.4,3.6c2.6,0,3.6-0.8,3.6-2c0-1.4-1.8-1.9-4.6-2.3c-4.6-0.8-11-1.7-11-8.5c0-4.7,4.1-8.8,11.5-8.8c4.5,0,8.3,1.4,11.2,3.6L89.8,21c-1.6-1.6-4.7-2.9-7.9-2.9c-2,0-3.4,0.7-3.4,1.8c0,1.2,1.4,1.7,4.3,2.2c4.6,0.8,11.3,1.9,11.3,9c0,5.1-4.5,8.9-12.2,8.9c-4.8,0-9.7-1.6-12.5-4.1L72.9,30z"/><path className="is-i-2" d="M96.5,4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9c0,2.7-2.2,4.9-4.9,4.9C98.7,9.8,96.5,7.6,96.5,4.9z M97.1,12.5h8.5v26.8h-8.5V12.5z"/><path className="is-d" d="M128.5,36.2c-2.2,2.6-4.9,3.8-7.9,3.8c-6.6,0-11.9-5-11.9-14.1c0-8.8,5.2-14,11.9-14c2.9,0,5.8,1.2,7.9,3.8V4.3l8.5-2v37h-8.5V36.2z M128.5,21.8c-1.1-1.4-3.3-2.4-5.2-2.4c-3.4,0-5.9,2.5-5.9,6.5s2.5,6.5,5.9,6.5c1.8,0,4.1-1,5.2-2.4V21.8z"/><path className="is-e" d="M154.3,11.8c7.9,0,13.7,5.8,13.7,14.9v1.8h-19.1c0.5,2.5,2.8,4.8,6.8,4.8c2.4,0,5-0.9,6.5-2.3l3.6,5.3c-2.7,2.4-7.1,3.6-11.2,3.6c-8.3,0-14.6-5.4-14.6-14.1C140,18.1,145.9,11.8,154.3,11.8z M148.8,23h11c-0.2-1.9-1.6-4.6-5.5-4.6C150.5,18.4,149.1,21.1,148.8,23z"/></g>,
    },



    party : {
      slug    : 'party',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M9.684,4.948a1,1,0,0,0,1.265-.632l1-3a1,1,0,0,0-1.9-.632l-1,3A1,1,0,0,0,9.684,4.948Z"/><path opacity={ministryOpacity} d="M22.684,12.052l-3,1a1,1,0,1,0,.632,1.9l3-1a1,1,0,1,0-.632-1.9Z"/><circle opacity={ministryOpacity} cx="16" cy="4" r="2.5"/><path opacity={ministryOpacity} d="M15,10a1,1,0,0,0,1,1,7.008,7.008,0,0,0,7-7,1,1,0,0,0-2,0,5.006,5.006,0,0,1-5,5A1,1,0,0,0,15,10Z"/><path d="M7.707,5.293a1,1,0,0,0-1.65.374l-6,17a1,1,0,0,0,1.276,1.276l17-6a1,1,0,0,0,.374-1.65ZM2.639,21.361l1.205-3.415.892,2.675Zm5.378-1.9L5.73,12.6l1.1-3.115,3.1,9.3Zm4.9-1.728-2.2-6.614,3.5,3.5L15,17Z"/></g>,
    },
    calendar : {
      slug    : 'calendar-date-2',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M19,3V1a1,1,0,0,0-2,0V3Z"/><path d="M7,3V1A1,1,0,0,0,5,1V3Z"/><path d="M21,23H3a3,3,0,0,1-3-3V7A3,3,0,0,1,3,4H21a3,3,0,0,1,3,3V20A3,3,0,0,1,21,23ZM22,9H2V20a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1Z"/><rect height="3" width="4" x="4" y="11"/><rect height="3" width="4" x="10" y="11"/><rect height="3" width="4" x="4" y="16"/><rect height="3" width="4" x="10" y="16"/><rect height="3" width="4" x="16" y="11"/></g>,
    },
    time : {
      slug    : 'time',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M11,5v7a1,1,0,0,0,1,1h7V11H13V5Z"/><path d="M12,0A12.013,12.013,0,0,0,0,12H2a10.016,10.016,0,1,1,4,8L7.8,17.6.6,17.2,3,24l1.8-2.406A12,12,0,1,0,12,0Z"/></g>,
    },
    money : {
      slug    : 'currency-dollar',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M17.51,13.1c-1.112-1.234-3.358-2.015-4.635-2.502V5.621c1.413,0.109,2.834,0.447,4.266,1.012l0.998-2.502 c-1.659-0.684-3.414-1.057-5.264-1.121V0.754h-1.818v2.297C9.379,3.224,8.051,3.716,7.071,4.527c-0.98,0.812-1.47,1.846-1.47,3.104 c0,1.176,0.344,2.163,1.032,2.96c1.215,1.409,3.309,2.07,4.423,2.481v5.113c-1.7-0.018-4.026-0.616-5.564-1.367v2.885 c1.449,0.656,3.304,1.003,5.564,1.039v2.885h1.818V20.66c1.823-0.2,3.217-0.729,4.184-1.586c0.966-0.856,1.449-1.946,1.449-3.268 C18.508,14.74,18.175,13.838,17.51,13.1z M11.057,9.928C10.209,9.591,9.623,9.249,9.3,8.902C8.976,8.556,8.814,8.123,8.814,7.604 c0-0.501,0.191-0.92,0.574-1.258c0.383-0.337,0.938-0.561,1.668-0.67V9.928z M12.875,18.076v-4.361 c0.866,0.319,1.483,0.649,1.853,0.991s0.554,0.777,0.554,1.306C15.281,17.142,14.479,17.83,12.875,18.076z"/></g>,
    },
    'money-circle' : {
      slug    : 'round-dollar',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M13.592,15.564c0.232-0.163,0.54-0.465,0.54-1.214c0-0.532-0.361-0.851-1.132-1.181v2.659 C13.225,15.759,13.432,15.677,13.592,15.564z"/><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M16.132,14.351 c0,1.549-0.756,2.405-1.391,2.851c-0.497,0.349-1.096,0.557-1.741,0.677V20h-2v-2.037c-1.151-0.087-2.325-0.361-3.34-0.727 l-0.94-0.339l0.679-1.881l0.941,0.34c0.92,0.332,1.834,0.53,2.66,0.606v-3.509c-1.589-0.57-3.352-1.46-3.352-3.722 c0-1.128,0.523-2.059,1.474-2.621C9.664,5.79,10.316,5.617,11,5.536V4h2v1.551c1.177,0.137,2.255,0.46,2.856,0.792l0.877,0.481 L15.77,8.577l-0.876-0.482C14.461,7.857,13.754,7.663,13,7.558v3.464C14.528,11.562,16.132,12.344,16.132,14.351z"/><path d="M10.141,7.833C9.796,8.037,9.648,8.305,9.648,8.731c0,0.757,0.429,1.17,1.352,1.571V7.556 C10.672,7.613,10.37,7.697,10.141,7.833z"/></g>,
    },
    feed : {
      slug    : 'playlist',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23,13H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h22c0.552,0,1,0.447,1,1S23.552,13,23,13z"/><path d="M23,6H1C0.448,6,0,5.553,0,5s0.448-1,1-1h22c0.552,0,1,0.447,1,1S23.552,6,23,6z"/><path d="M12,20H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h11c0.552,0,1,0.447,1,1S12.552,20,12,20z"/></g>,
    },
    'feed-bold' : {
      slug    : 'menu-bold',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23,10H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1v-2C24,10.4,23.6,10,23,10z"/><path d="M23,2H1C0.4,2,0,2.4,0,3v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V3C24,2.4,23.6,2,23,2z"/><path d="M23,18H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1v-2C24,18.4,23.6,18,23,18z"/></g>,
    },
    grid : {
      slug    : 'gallery-view',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23,11V3a2,2,0,0,0-2-2H13V11Z"/><path d="M11,1H3A2,2,0,0,0,1,3v8H11Z"/><path d="M1,13v8a2,2,0,0,0,2,2h8V13Z"/><path d="M13,23h8a2,2,0,0,0,2-2V13H13Z"/></g>,
    },
    pen : {
      slug    : 'pen-tool',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M23.707,10.293l-10-10c-0.391-0.391-1.023-0.391-1.414,0l-2,2 c-0.391,0.391-0.391,1.023,0,1.414l10,10C20.488,13.902,20.744,14,21,14s0.512-0.098,0.707-0.293l2-2 C24.098,11.316,24.098,10.684,23.707,10.293z"/><path d="M9.592,5.835L3.649,8.063C3.31,8.19,3.067,8.49,3.012,8.848L0.351,22.235L6.3,16.286 C6.113,15.895,6,15.462,6,15c0-1.657,1.343-3,3-3s3,1.343,3,3s-1.343,3-3,3c-0.462,0-0.895-0.113-1.286-0.3l-5.949,5.949 l13.387-2.661c0.357-0.055,0.657-0.299,0.784-0.637l2.229-5.944L9.592,5.835z"/></g>,
    },
    teach : {
      slug    : 'presentation',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><circle cx="4" cy="3" r="3"/><path d="M16,9V7H3A2,2,0,0,0,1,9V24H3V17H5v7H7V9Z"/><path opacity={ministryOpacity} d="M23,2H9V4H22V15H9v2h7.341l2.74,6.394A1,1,0,0,0,20,24a.979.979,0,0,0,.394-.081,1,1,0,0,0,.525-1.313L18.516,17H23a1,1,0,0,0,1-1V3A1,1,0,0,0,23,2Z"/></g>,
    },
    bulb : {
      slug    : 'bulb-61',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M8,17.06V20h8v-2.94c3.059-1.514,5-4.607,5-8.06c0-4.963-4.038-9-9-9S3,4.037,3,9 C3,12.452,4.941,15.546,8,17.06z M7,9c0-2.757,2.243-5,5-5h1v2h-1c-1.654,0-3,1.346-3,3v1H7V9z"/><path opacity={ministryOpacity} d="M8,22v1c0,0.553,0.448,1,1,1h6c0.552,0,1-0.447,1-1v-1H8z"/></g>,
    },
    'water' : {
      slug    : 'water-surface',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M1,16a3.962,3.962,0,0,0,3.138-1.528,3.033,3.033,0,0,1,4.723,0,3.992,3.992,0,0,0,5.6.682,4.075,4.075,0,0,0,.682-.682,3,3,0,0,1,4.724,0A3.961,3.961,0,0,0,23,16a2.972,2.972,0,0,1,.965.173A.9.9,0,0,0,24,16V12a1,1,0,0,0-1-1,5.946,5.946,0,0,1-4.712-2.295,1,1,0,0,0-.788-.384h0a1,1,0,0,0-.787.383,5.982,5.982,0,0,1-9.425,0,1.037,1.037,0,0,0-1.576,0A5.946,5.946,0,0,1,1,11a1,1,0,0,0-1,1v4a.9.9,0,0,0,.035.173A2.976,2.976,0,0,1,1,16Z"/><path d="M1,9A3.962,3.962,0,0,0,4.138,7.472a3.033,3.033,0,0,1,4.723,0,3.992,3.992,0,0,0,5.6.682,4.075,4.075,0,0,0,.682-.682,3,3,0,0,1,4.724,0A3.961,3.961,0,0,0,23,9a2.972,2.972,0,0,1,.965.173A.9.9,0,0,0,24,9V5a1,1,0,0,0-1-1,5.946,5.946,0,0,1-4.712-2.295,1,1,0,0,0-.788-.384h0a1,1,0,0,0-.787.383,6.019,6.019,0,0,1-1.024,1.025,5.991,5.991,0,0,1-8.4-1.024,1.037,1.037,0,0,0-1.576,0A5.946,5.946,0,0,1,1,4,1,1,0,0,0,0,5V9a.9.9,0,0,0,.035.173A2.976,2.976,0,0,1,1,9Z"/><path d="M23,18a5.946,5.946,0,0,1-4.712-2.295,1,1,0,0,0-.788-.384h0a1,1,0,0,0-.787.383,5.982,5.982,0,0,1-9.425,0,1.037,1.037,0,0,0-1.576,0A5.946,5.946,0,0,1,1,18a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V19A1,1,0,0,0,23,18Z"/></g>,
    },
    'water-drop' : {
      slug    : 'drop',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12.71.3a1.031,1.031,0,0,0-1.42,0C10.952.637,3,8.711,3,14a8.913,8.913,0,0,0,9,9,8.913,8.913,0,0,0,9-9C21,8.711,13.048.637,12.71.3ZM13,18H12a3.935,3.935,0,0,1-4-4V13h2v1a2,2,0,0,0,2,2h1Z"/></g>,
    },
    'water-drops' : {
      slug    : 'drops',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M11.3,8.3C10.8,8.8,6,13.7,6,17c0,3.7,3.1,6,6,6s6-2.2,6-6c0-3.4-4.8-8.2-5.3-8.8 C12.3,7.9,11.7,7.9,11.3,8.3z"/><path opacity={ministryOpacity} d="M20.7,1.3c-0.4-0.4-1.1-0.4-1.4,0C18.7,1.9,16,4.8,16,7c0,2.5,2,4,4,4s4-1.5,4-4C24,4.8,21.3,1.9,20.7,1.3z"/><path opacity={ministryOpacity} d="M8,7c0-2.2-2.7-5.1-3.3-5.6c-0.4-0.4-1.1-0.4-1.4,0C2.7,1.9,0,4.8,0,7c0,2.5,2,4,4,4S8,9.5,8,7z"/></g>,
    },
    'water-hand' : {
      slug    : 'water-hand',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M22.99609,16.90869C22.90332,15.90234,21.94922,14,19,14h-5.00195C13.08496,12.78613,11.63281,12,10,12 H7.47168C5.94043,10.37207,3.05957,10,2,10H0v8.61816l7.36914,3.68457c0.56055,0.28027,1.1748,0.42139,1.79004,0.42139 c0.48633,0,0.97363-0.08838,1.43555-0.26611l12.4707-4.79639L22.99609,16.90869z M9.87598,20.59131 c-0.52246,0.20068-1.11035,0.17334-1.6123-0.07764L2,17.38184V12c1.52306,0,3.41983,0.48669,4.47949,2H10 c1.30371,0,2.41602,0.83594,2.8291,2H7c-0.55273,0-1,0.44775-1,1s0.44727,1,1,1h8v-1c0-0.34229-0.03418-0.67676-0.10059-1H19 c0.84766,0,1.33789,0.21631,1.62207,0.4585L9.87598,20.59131z"/><path opacity={ministryOpacity} d="M14,11c1.96582,0,4-1.49609,4-4c0-2.19336-2.7334-5.12939-3.28125-5.69531L14,0.56201 l-0.71875,0.74268C12.7334,1.87061,10,4.80664,10,7C10,9.50391,12.03418,11,14,11z"/></g>,
    },
    secure : {
      slug    : 'lock-circle',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M21.217,3.023l-9-2a1.024,1.024,0,0,0-.434,0l-9,2A1,1,0,0,0,2,4V14a10,10,0,0,0,20,0V4A1,1,0,0,0,21.217,3.023ZM17,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H9V9a3,3,0,0,1,6,0v2h1a1,1,0,0,1,1,1Z"/><path d="M12,8a1,1,0,0,0-1,1v2h2V9A1,1,0,0,0,12,8Z"/></g>,
    },
    video : {
      slug    : 'video-playlist',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M21,3H3V2A1,1,0,0,1,4,1H20a1,1,0,0,1,1,1Z"/><path d="M23,5H1A1,1,0,0,0,0,6V22a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V6A1,1,0,0,0,23,5ZM10,18V10l6,4Z"/></g>,
    },
    play : {
      slug    : 'button-play',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M20.555,11.168l-15-10c-0.307-0.204-0.702-0.224-1.026-0.05C4.203,1.292,4,1.631,4,2v20 c0,0.369,0.203,0.708,0.528,0.882C4.676,22.961,4.838,23,5,23c0.194,0,0.388-0.057,0.555-0.168l15-10C20.833,12.646,21,12.334,21,12 S20.833,11.354,20.555,11.168z"/></g>,
    },
    'play-circle' : {
      slug    : 'btn-play',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M9,17V7l8,5L9,17z"/></g>,
    },
    pause : {
      slug    : 'button-pause',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M7,1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V2A1,1,0,0,0,7,1Z"/><path d="M22,1H17a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V2A1,1,0,0,0,22,1Z"/></g>,
    },
    'pause-circle' : {
      slug    : 'btn-play',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M10,17H8V7h2V17z M16,17h-2V7h2 V17z"/></g>,
    },
    book : {
      slug    : 'book',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M11,2.1A8.814,8.814,0,0,0,6.5,1C2.8,1,0,2.7,0,5V22a.945.945,0,0,0,1,1,.945.945,0,0,0,1-1c0-.8,1.8-2,4.5-2a7.469,7.469,0,0,1,3.094.618A.992.992,0,0,0,11,19.71Z"></path><path d="M17.5,1A8.814,8.814,0,0,0,13,2.1V19.71a.992.992,0,0,0,1.406.908A7.469,7.469,0,0,1,17.5,20c2.7,0,4.5,1.2,4.5,2a1,1,0,0,0,2,0V5C24,2.7,21.2,1,17.5,1Z"></path></g>,
    },
    person : {
      slug    : 'circle-09',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M12,22 c-2.693,0-5.136-1.075-6.936-2.813C5.362,17.278,6.74,16.54,8.5,15.954c1.183-0.394,1.694-1.437,1.919-2.281 C8.997,13.059,8,11.647,8,10V9c0-2.209,1.791-4,4-4s4,1.791,4,4v1c0,1.647-0.997,3.059-2.419,3.673 c0.227,0.846,0.741,1.889,1.919,2.281c1.762,0.587,3.141,1.302,3.437,3.233C17.137,20.925,14.693,22,12,22z"></path></g>,
    },
    mic : {
      slug    : 'microphone',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,16c3.3,0,6-2.7,6-6V6c0-3.3-2.7-6-6-6S6,2.7,6,6v4C6,13.3,8.7,16,12,16z"/><path d="M22,10c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-0.6-0.4-1-1-1s-1,0.4-1,1 c0,5.2,4,9.4,9,9.9V22H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4v-2.1C18,19.4,22,15.2,22,10z"/></g>,
    },
    ministries : {
      slug    : 'multiple-11',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,6L12,6c-1.657,0-3-1.343-3-3v0c0-1.657,1.343-3,3-3h0c1.657,0,3,1.343,3,3v0C15,4.657,13.657,6,12,6z"/><path d="M4,19v-8c0-1.13,0.391-2.162,1.026-3H2c-1.105,0-2,0.895-2,2v6h2v5c0,0.552,0.448,1,1,1h2 c0.552,0,1-0.448,1-1v-2H4z"/><path d="M14,24h-4c-0.552,0-1-0.448-1-1v-6H6v-6c0-1.657,1.343-3,3-3h6c1.657,0,3,1.343,3,3v6h-3v6 C15,23.552,14.552,24,14,24z"/><path d="M4,7L4,7C2.895,7,2,6.105,2,5v0c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2v0 C6,6.105,5.105,7,4,7z"/><path d="M20,19v-8c0-1.13-0.391-2.162-1.026-3H22c1.105,0,2,0.895,2,2v6h-2v5c0,0.552-0.448,1-1,1h-2 c-0.552,0-1-0.448-1-1v-2H20z"/><path d="M20,7L20,7c1.105,0,2-0.895,2-2v0c0-1.105-0.895-2-2-2h0c-1.105,0-2,0.895-2,2v0 C18,6.105,18.895,7,20,7z"/></g>,
    },
    childcare : {
      slug    : 'child',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><circle cx="8.5" cy="14.5" r="1.5"/><circle cx="15.5" cy="14.5" r="1.5"/><path d="M21.245,9.206c-1.265-3.083-3.972-5.3-7.165-5.982c0.697-0.726,1.693-1.145,2.982-1.226l-0.125-1.996 c-1.881,0.117-3.43,0.812-4.479,2.01c-0.266,0.304-0.495,0.638-0.691,0.997c-3.971,0.092-7.51,2.534-9.013,6.197 C1.128,9.734,0,11.246,0,13s1.128,3.266,2.755,3.794C4.287,20.528,7.935,23,12,23s7.713-2.472,9.245-6.206 C22.872,16.266,24,14.754,24,13S22.872,9.734,21.245,9.206z M20.33,14.964l-0.585,0.098l-0.194,0.561C18.433,18.839,15.398,21,12,21 s-6.433-2.161-7.551-5.378l-0.194-0.561L3.67,14.964C2.702,14.802,2,13.977,2,13s0.702-1.802,1.67-1.964l0.585-0.098l0.194-0.561 c1.016-2.924,3.62-4.956,6.637-5.306c-0.101,0.625-0.134,1.292-0.084,1.997l1.996-0.139c-0.047-0.674,0.006-1.285,0.13-1.841 c2.926,0.419,5.43,2.434,6.422,5.289l0.194,0.561l0.585,0.098C21.298,11.198,22,12.023,22,13S21.298,14.802,20.33,14.964z"/></g>,
    },
    rsvp : {
      slug    : 'n-check',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M11,23A11,11,0,0,1,11,1a10.832,10.832,0,0,1,5.83,1.652l-1.06,1.7A8.864,8.864,0,0,0,11,3a9,9,0,1,0,9,9,9.9,9.9,0,0,0-.27-2.358l1.94-.484A11.909,11.909,0,0,1,22,12,11.013,11.013,0,0,1,11,23Z"/><path d="M11,15a1,1,0,0,1-.707-.293L5.586,10,7,8.586l4,4,11-11L23.414,3,11.707,14.707A1,1,0,0,1,11,15Z"/></g>,
    },
    'rsvp-circle' : {
      slug    : 'c-check',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z"/></g>,
    },
    location : {
      slug    : 'pin-3',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0C7.6,0,3,3.4,3,9c0,5.3,8,13.4,8.3,13.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3C13,22.4,21,14.3,21,9 C21,3.4,16.4,0,12,0z M12,12c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S13.7,12,12,12z"/></g>,
    },
    'location-map' : {
      slug    : 'map-gps',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23.615,1.212c-0.243-0.19-0.56-0.258-0.858-0.182l-7.717,1.93L9.316,1.051 C9.135,0.991,8.941,0.983,8.757,1.03l-8,2C0.312,3.141,0,3.541,0,4v15c0,0.308,0.142,0.599,0.385,0.788C0.562,19.927,0.779,20,1,20 c0.081,0,0.163-0.01,0.243-0.03l5.024-1.256l-0.526-1.93L2,17.719V4.781l6.96-1.74l5.724,1.908c0.18,0.06,0.374,0.067,0.559,0.021 L22,3.281v12.938l-6.243,1.561c-0.536,0.134-0.861,0.677-0.728,1.213c0.135,0.536,0.678,0.862,1.213,0.728l7-1.75 C23.688,17.859,24,17.459,24,17V2C24,1.692,23.858,1.401,23.615,1.212z"/><path d="M18.263,12.035l-11-3c-0.346-0.093-0.716,0.004-0.97,0.258s-0.352,0.624-0.258,0.97l3,11 c0.105,0.386,0.431,0.673,0.828,0.728C9.909,21.997,9.955,22,10,22c0.348,0,0.675-0.182,0.857-0.485l2.872-4.786l4.786-2.872 c0.343-0.206,0.531-0.597,0.476-0.994S18.649,12.141,18.263,12.035z"/></g>,
    },
    'location-globe' : {
      slug    : 'world-pin',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M20.8,11.4c0.1,0.5,0.2,1.1,0.2,1.6c0,1.4-0.3,2.8-0.9,4c-2,1.1-2.9,0.5-3.8-0.2c-0.6-0.5-1.5-1.1-2.6-0.7 c-1.1,0.4-1.9,1.3-2.4,2.4c-0.4,1-0.5,2.3-0.3,3.5c-2-0.2-3.8-1.1-5.1-2.4c0.7-1.5,0.7-3-0.2-4.3c-0.2-0.4-0.5-0.7-0.8-1 c-0.5-0.5-0.3-1.4,0.3-1.7c0.8-0.4,1.7-0.8,2.3-1.6C8.7,9.2,7.3,6.6,7,6.1C6.9,6,6.8,5.9,6.7,5.7C7.7,5,8.8,4.5,10,4.2 c0.1-0.8,0.3-1.5,0.6-2.1C5.2,2.8,1,7.4,1,13c0,6.1,4.9,11,11,11s11-4.9,11-11c0-1.2-0.2-2.4-0.6-3.6C21.9,10.1,21.4,10.8,20.8,11.4 z"/><path d="M17,0c-2.5,0-5,1.9-5,5c0,2.8,3.9,6.4,4.3,6.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3 C18.1,11.4,22,7.8,22,5C22,1.9,19.5,0,17,0z M17,7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C19,6.1,18.1,7,17,7z"/></g>,
    },
    'location-marker' : {
      slug    : 'marker-2',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M17.736,16.677 C17.542,16.887,17.274,17,17,17c-0.125,0-0.251-0.023-0.372-0.071L12,15.077l-4.628,1.852c-0.385,0.154-0.826,0.054-1.107-0.251 c-0.281-0.306-0.344-0.753-0.159-1.125l5-10c0.339-0.678,1.45-0.678,1.789,0l5,10C18.08,15.924,18.017,16.372,17.736,16.677z"/></g>,
    },
    roadmap : {
      slug    : 'roadmap',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M23.781,14.375l-4-5A1,1,0,0,0,19,9v5a3,3,0,0,1-3,3H7v3a1,1,0,0,0,1,1H19a1,1,0,0,0,.781-.375l4-5A1,1,0,0,0,23.781,14.375Z"/><path d="M16,15H5a1,1,0,0,1-.781-.375l-4-5a1,1,0,0,1,0-1.25l4-5A1,1,0,0,1,5,3H16a1,1,0,0,1,1,1V14A1,1,0,0,1,16,15Z"/></g>,
    },
    flag : {
      slug    : 'flag-diagonal-34',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M3.1,5.3C2.8,4.8,2.2,4.6,1.8,4.9C1.3,5.2,1.1,5.8,1.4,6.3l9.5,16.5c0.2,0.3,0.5,0.5,0.9,0.5 c0.2,0,0.3,0,0.5-0.1c0.5-0.3,0.6-0.9,0.4-1.4L3.1,5.3z"/><path d="M21.7,4.3c-0.3-0.3-0.7-0.4-1-0.3c-1.2,0.3-3-0.1-5-0.5c-1.6-0.4-3.3-0.8-5-0.8c-2.3,0-4.1,0.8-5.5,2.4 l5.5,9.6c0.2-0.1,0.3-0.2,0.4-0.4c1.1-2,2.6-2.6,4.4-3.2c2.2-0.8,4.7-1.8,6.3-5.7C22.1,5,22,4.6,21.7,4.3z"/></g>,
    },
    map : {
      slug    : 'map',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23.6,1.2C23.4,1,23,1,22.7,1L17,2.7v20.1l6.3-1.8c0.4-0.1,0.7-0.5,0.7-1V2C24,1.7,23.9,1.4,23.6,1.2z"/><polygon opacity={ministryOpacity} points="15,2.7 9,1.2 9,21.3 15,22.8 "/><path d="M7,1.2L0.7,3C0.3,3.2,0,3.6,0,4v18c0,0.3,0.1,0.6,0.4,0.8C0.6,22.9,0.8,23,1,23c0.1,0,0.2,0,0.3,0L7,21.3 V1.2z"/></g>,
    },
    at : {
      slug    : 'at-sign',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M24,10.7a10.687,10.687,0,0,1-.676,3.868c-.84,2.176-2.461,3.75-4.777,3.75a3.314,3.314,0,0,1-3.131-1.894,6.1,6.1,0,0,1-4.276,1.894,5.412,5.412,0,0,1-4.1-1.563c-2.009-2.1-2.177-6.724.47-9.269,2.152-2.069,4.968-2.124,7.929-1.652a15.284,15.284,0,0,1,2.506.58l-.338,7.178q0,2.04,1.117,2.04.94,0,1.5-1.373a9.663,9.663,0,0,0,.559-3.588A8.616,8.616,0,0,0,19.8,6.5a7.4,7.4,0,0,0-6.849-3.721A10.437,10.437,0,0,0,7.789,3.993C4.631,5.751,3.263,9.139,3.263,12.815a8.4,8.4,0,0,0,2.168,6.215c3.156,3.155,9.2,2.348,13.381.815v2.819A17.693,17.693,0,0,1,11.831,24q-5.585,0-8.708-2.936T0,12.9A12.493,12.493,0,0,1,6.136,1.681,13.453,13.453,0,0,1,12.919,0c4,0,7.726,1.6,9.7,5.079A11.208,11.208,0,0,1,24,10.7ZM8.951,12.536q0,3.1,2.528,3.1c2.146,0,2.7-1.818,2.851-3.964l.191-3.243a8.009,8.009,0,0,0-1.69-.163C10.262,8.264,8.951,10.018,8.951,12.536Z"/></g>,
    },
    circle : {
      slug    : 'oval',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,1A11,11,0,1,0,23,12,11.012,11.012,0,0,0,12,1Z"/></g>,
    },
    plan : {
      slug    : 'tactic',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M21,0H3C2.4,0,2,0.4,2,1v22c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1V1C22,0.4,21.6,0,21,0z M11,9h5v2h-5V9z M9,19H6v-2h3V19z M9,15H6v-2h3V15z M9,11H6V9h3V11z M9,7H6V5h3V7z M15,19h-4v-2h4V19z M18,15h-7v-2h7V15z M18,7h-7V5h7V7z"/></g>,
    },
    radio : {
      slug    : 'radio-btn',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z"/></g>,
    },
    'radio-checked' : {
      slug    : 'radio-btn-checked',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,17a5,5,0,1,1,5-5A5.006,5.006,0,0,1,12,17Z"/></g>,
    },
    'checkbox' : {
      slug    : 'checkbox-btn',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M20,23H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1H20a3,3,0,0,1,3,3V20A3,3,0,0,1,20,23ZM4,3A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1Z"/></g>,
    },
    'checkbox-checked' : {
      slug    : 'checkbox-btn-checked',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M20,1H4A3,3,0,0,0,1,4V20a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V4A3,3,0,0,0,20,1ZM18.707,7.707l-9,9a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,1.414-1.414L9,14.586l8.293-8.293a1,1,0,1,1,1.414,1.414Z"/></g>,
    },



    'life-groups' : {
      slug    : 'layers-3',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><polygon points="12,0 24,7 12,14 0,7 "/><polygon opacity={ministryOpacity} points="12,21.315 2.301,15.658 0,17 12,24 24,17 21.699,15.658 "/><polygon opacity={ministryOpacity} points="12,16.315 2.301,10.658 0,12 12,19 24,12 21.699,10.658 "/></g>,
    },
    men : {
      slug    : 'steps',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M18,0c-2.9,0-5,2.6-5,6c0,1.2,0.2,2,0.4,2.9C13.7,9.9,14,11,14,13c0,0.6,0.4,1,1,1h6c0.5,0,0.9-0.3,1-0.8 c0-0.2,1-4.2,1-7.2C23,2.6,20.9,0,18,0z"/><path d="M6,2C3.1,2,1,4.6,1,8c0,3.1,1,7.1,1,7.2C2.1,15.7,2.5,16,3,16h6c0.6,0,1-0.4,1-1c0-2,0.3-3.1,0.6-4.1 C10.8,10,11,9.2,11,8C11,4.6,8.9,2,6,2z"/><path d="M9.3,18H2.7c-0.4,0-0.8,0.3-0.9,0.7h0c-0.2,0.8-0.2,1.7,0.1,2.5C2.7,23,4.3,24,6,24 c1.6,0,3.1-0.9,3.8-2.4c0.4-0.9,0.5-1.9,0.3-2.9h0C10.1,18.3,9.7,18,9.3,18z"/><path opacity={ministryOpacity} d="M21.3,16h-6.6c-0.4,0-0.8,0.3-0.9,0.7h0c-0.2,0.8-0.2,1.7,0.1,2.5c0.7,1.7,2.3,2.8,4,2.8 c1.6,0,3.1-0.9,3.8-2.4c0.4-0.9,0.5-1.9,0.3-2.9l0,0C22.1,16.3,21.7,16,21.3,16z"/></g>,
    },
    women : {
      slug    : 'macro',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M20.447,14.105c-0.338-0.169-0.744-0.132-1.047,0.095L13,19v-4h-2v4l-6.4-4.8 c-0.303-0.228-0.709-0.265-1.047-0.095C3.214,14.275,3,14.621,3,15v4c0,2.757,2.243,5,5,5h8c2.757,0,5-2.243,5-5v-4 C21,14.621,20.786,14.275,20.447,14.105z"/><path d="M12,13c3.859,0,7-3.14,7-7V0.131l-4,2.667l-3-2l-3,2L5,0.131V6C5,9.86,8.141,13,12,13z"/></g>,
    },
    // students : {
    //   slug    : 'mountain',
    //   width   : '24',
    //   height  : '24',
    //   viewbox : '0 0 24 24',
    //   markup  : <g><polygon points="15.6792,8 12,2.11328 8.3208,8 "/><polygon opacity={ministryOpacity} points="16.9292,10 7.0708,10 0.1958,21 23.8042,21 "/></g>,
    // },
    // students : {
    //   slug    : 'notification-2',
    //   width   : '24',
    //   height  : '24',
    //   viewbox : '0 0 24 24',
    //   markup  : <g><circle cx="12" cy="12" r="5"/><path opacity={ministryOpacity} d="M7.455,20.91a10,10,0,0,1,0-17.82,1,1,0,0,0-.91-1.781,12,12,0,0,0,0,21.382A.987.987,0,0,0,7,22.8a1,1,0,0,0,.456-1.891Z"/><path opacity={ministryOpacity} d="M17.455,1.309a1,1,0,0,0-.91,1.781,10,10,0,0,1,0,17.82A1,1,0,0,0,17,22.8a.987.987,0,0,0,.454-.11,12,12,0,0,0,0-21.382Z"/></g>,
    // },
    students : {
      slug    : 'notification-70',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path opacity={ministryOpacity} d="M20,9a2.99,2.99,0,0,0-2.658-2.966A12.554,12.554,0,0,0,17,9a12.554,12.554,0,0,0,.342,2.966A2.99,2.99,0,0,0,20,9Z"/><path opacity={ministryOpacity} d="M11.667,17.911,5.879,16.066l2.632,6.475A2.329,2.329,0,0,0,12.832,20.8Z"/><path d="M19,0a3.134,3.134,0,0,0-1.054.185L2.73,5.037A3.818,3.818,0,0,0,0,9a3.809,3.809,0,0,0,2.7,3.953l15.25,4.862A3.134,3.134,0,0,0,19,18c2.851,0,5-3.869,5-9S21.851,0,19,0Zm0,16c-1.416,0-3-2.994-3-7s1.584-7,3-7,3,2.994,3,7S20.416,16,19,16Z"/></g>,
    },
    children : {
      slug    : 'shape-adjust',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23,9H10c-0.552,0-1,0.447-1,1v13c0,0.553,0.448,1,1,1h13c0.552,0,1-0.447,1-1V10C24,9.447,23.552,9,23,9z"/><path opacity={ministryOpacity} d="M7,9c0-1.105,0.895-2,2-2h8.768c-1.022-4.48-5.387-7.701-10.325-6.869 C3.74,0.756,0.755,3.742,0.131,7.446C-0.7,12.383,2.521,16.747,7,17.768V9z"/></g>,
    },
    'young-adults' : {
      slug    : 'position-marker',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M19,7c0-3.86-3.141-7-7-7S5,3.14,5,7c0,3.519,2.614,6.432,6,6.92V20h2v-6.08C16.386,13.432,19,10.519,19,7z"/><path opacity={ministryOpacity} d="M15,16.118v1.998c4.146,0.331,6.656,1.293,6.986,1.883c-0.404,0.722-4.061,2-9.986,2 c-6.043,0-9.727-1.33-10.006-1.958c0.229-0.586,2.76-1.586,7.006-1.925v-1.999C4.796,16.438,0,17.482,0,20c0,3.158,7.543,4,12,4 s12-0.842,12-4C24,17.482,19.204,16.438,15,16.118z"/></g>,
    },
    creative : {
      slug    : 'fog',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M1.5,7.9l2.8-1.7c1-0.6,2.2-0.6,3.2,0.1l1.7,1.1C10.1,7.9,11,8.2,12,8.2s1.9-0.3,2.8-0.8l1.7-1.1 c1-0.6,2.2-0.7,3.2-0.1l2.8,1.7C23,8.1,23.6,8,23.9,7.5C24.1,7,24,6.4,23.5,6.1l-2.8-1.7c-1.6-1-3.7-0.9-5.3,0.1l-1.7,1.1 c-1,0.7-2.3,0.7-3.3,0L8.7,4.6C7.1,3.5,5,3.5,3.3,4.4L0.5,6.1C0,6.4-0.1,7,0.1,7.5C0.4,8,1,8.1,1.5,7.9z"/><path d="M23.5,18.2l-2.8-1.7c-1.6-1-3.7-0.9-5.3,0.1l-1.7,1.1c-1,0.7-2.3,0.7-3.3,0l-1.7-1.1 c-1.6-1.1-3.7-1.1-5.3-0.1l-2.8,1.7c-0.5,0.3-0.6,0.9-0.3,1.4c0.3,0.5,0.9,0.6,1.4,0.3l2.8-1.7c1-0.6,2.2-0.6,3.2,0.1l1.7,1.1 c0.8,0.6,1.8,0.8,2.8,0.8c1,0,1.9-0.3,2.8-0.8l1.7-1.1c1-0.6,2.2-0.7,3.2-0.1l2.8,1.7c0.5,0.3,1.1,0.1,1.4-0.3 C24.1,19.1,24,18.5,23.5,18.2z"/><path opacity={ministryOpacity} d="M23.5,12.2l-2.8-1.7c-1.7-1-3.7-0.9-5.3,0.1l-1.7,1.1c-1,0.7-2.3,0.7-3.3,0l-1.7-1.1C7.1,9.5,5,9.5,3.3,10.5 l-2.8,1.7c-0.5,0.3-0.6,0.9-0.3,1.4s0.9,0.6,1.4,0.3l2.8-1.7c1-0.6,2.2-0.6,3.2,0.1l1.7,1.1c0.8,0.6,1.8,0.8,2.8,0.8 c1,0,1.9-0.3,2.8-0.8l1.7-1.1c1-0.6,2.2-0.7,3.2-0.1l2.8,1.7c0.5,0.3,1.1,0.1,1.4-0.3C24.1,13.1,24,12.5,23.5,12.2z"/></g>,
    },
    // care : {
    //   slug    : 'notification-2',
    //   width   : '24',
    //   height  : '24',
    //   viewbox : '0 0 24 24',
    //   markup  : <g><circle cx="12" cy="12" r="5"/><path opacity={ministryOpacity} d="M7.455,20.91a10,10,0,0,1,0-17.82,1,1,0,0,0-.91-1.781,12,12,0,0,0,0,21.382A.987.987,0,0,0,7,22.8a1,1,0,0,0,.456-1.891Z"/><path opacity={ministryOpacity} d="M17.455,1.309a1,1,0,0,0-.91,1.781,10,10,0,0,1,0,17.82A1,1,0,0,0,17,22.8a.987.987,0,0,0,.454-.11,12,12,0,0,0,0-21.382Z"/></g>,
    // },
    care : {
      slug    : 'hands-heart',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M17.041.974A3.241,3.241,0,0,0,12,1.488,3.24,3.24,0,0,0,6.959.974a3.362,3.362,0,0,0,0,4.7L12,10.8l5.043-5.123A3.366,3.366,0,0,0,17.041.974Z"/><path opacity={ministryOpacity} d="M9.414,16.414h0L6,13a1.471,1.471,0,0,0-2,0,1.417,1.417,0,0,0,0,2l3,3a.5.5,0,0,1-.7.719l-.012-.012-3-3A2.417,2.417,0,0,1,3,12.652V8.5a1.5,1.5,0,0,0-3,0v6.8a2,2,0,0,0,.438,1.25L4,21v3h6V17.828A2,2,0,0,0,9.414,16.414Z"/><path opacity={ministryOpacity} d="M14.586,16.414h0L18,13a1.471,1.471,0,0,1,2,0,1.417,1.417,0,0,1,0,2l-3,3a.5.5,0,0,0,.7.719l.012-.012,3-3A2.417,2.417,0,0,0,21,12.652V8.5a1.5,1.5,0,0,1,3,0v6.8a2,2,0,0,1-.438,1.25L20,21v3H14V17.828A2,2,0,0,1,14.586,16.414Z"/></g>,
    },
    missions : {
      slug    : 'book',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M11,2.1A8.814,8.814,0,0,0,6.5,1C2.8,1,0,2.7,0,5V22a.945.945,0,0,0,1,1,.945.945,0,0,0,1-1c0-.8,1.8-2,4.5-2a7.469,7.469,0,0,1,3.094.618A.992.992,0,0,0,11,19.71Z"/><path opacity={ministryOpacity} d="M17.5,1A8.814,8.814,0,0,0,13,2.1V19.71a.992.992,0,0,0,1.406.908A7.469,7.469,0,0,1,17.5,20c2.7,0,4.5,1.2,4.5,2a1,1,0,0,0,2,0V5C24,2.7,21.2,1,17.5,1Z"/></g>,
    },



    search : {
      slug    : 'search',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g><path d="M30.414,27.586,22.77,19.942a12.038,12.038,0,1,0-2.828,2.828l7.644,7.644ZM3,13A10,10,0,1,1,13,23,10.011,10.011,0,0,1,3,13Z"/></g>,
    },
    menu : {
      slug    : 'menu-34',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g><path d="M30,15H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,15,30,15z"/><path d="M30,6H2C1.4,6,1,6.4,1,7s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,6,30,6z"/><path d="M30,24H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,24,30,24z"/></g>,
    },
    more : {
      slug    : 'menu-dots',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g><circle cx="4" cy="16" r="3"/><circle cx="16" cy="16" r="3"/><circle cx="28" cy="16" r="3"/></g>,
    },
    rarr : {
      slug    : 'arrow-right',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z"/></g>,
    },
    larr : {
      slug    : 'arrow-left',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M1.293,11.293L9,3.586L10.414,5l-6,6H22c0.553,0,1,0.448,1,1s-0.447,1-1,1H4.414l6,6L9,20.414l-7.707-7.707 C0.902,12.316,0.902,11.684,1.293,11.293z"/></g>,
    },
    nearr : {
      slug    : 'fullscreen-double-74',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M22,1h-9v2h6.6l-10,10l1.4,1.4l10-10V11h2V2C23,1.4,22.6,1,22,1z"/></g>,
    },
    'ctrl-left' : {
      slug    : 'ctrl-left',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M15.191,23.588l-8-11c-0.255-0.351-0.255-0.825,0-1.176l8-11l1.617,1.176L9.236,12l7.572,10.412 L15.191,23.588z"/></g>,
    },
    'ctrl-right' : {
      slug    : 'ctrl-right',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M8.809,23.588l-1.617-1.176L14.764,12L7.191,1.588l1.617-1.176l8,11c0.255,0.351,0.255,0.825,0,1.176 L8.809,23.588z"/></g>,
    },
    'close' : {
      slug    : 'e-remove',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"/></g>,
    },
    'close-fat' : {
      slug    : 'd-remove',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M22.7,4.3l-3-3c-0.4-0.4-1-0.4-1.4,0L12,7.6L5.7,1.3c-0.4-0.4-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4L7.6,12 l-6.3,6.3c-0.4,0.4-0.4,1,0,1.4l3,3c0.4,0.4,1,0.4,1.4,0l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3 c0.4-0.4,0.4-1,0-1.4L16.4,12l6.3-6.3C23.1,5.3,23.1,4.7,22.7,4.3z"/></g>,
    },
    'studies' : {
      slug    : 'open-book',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M11,3.691,1.263,1.035A1,1,0,0,0,0,2V15a1,1,0,0,0,.737.965L11,18.764Z"></path><path d="M23.607,1.205a1.012,1.012,0,0,0-.87-.17L13,3.691V18.764l10.263-2.8A1,1,0,0,0,24,15V2A1,1,0,0,0,23.607,1.205Z"></path><path d="M23.788,17.9l-11,3a3.017,3.017,0,0,1-1.578,0l-11-3c-.073-.02-.14-.052-.211-.077V22a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V17.817C23.929,17.843,23.862,17.875,23.788,17.9Z"></path></g>,
    },
    'life-groups' : {
      slug    : 'users-meeting',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M23,0H12c-.552,0-1,.448-1,1V9.149c0,.945,1.19,1.363,1.781,.625l2.219-2.774h8c.552,0,1-.448,1-1V1c0-.552-.448-1-1-1Z"></path><path d="M5.5,17c-3.032,0-5.5,2.467-5.5,5.5v.5c0,.552,.447,1,1,1H10c.553,0,1-.448,1-1v-.5c0-3.033-2.468-5.5-5.5-5.5Z"></path><circle cx="5.5" cy="12.5" r="3.5"></circle><path d="M18.5,17c-3.032,0-5.5,2.467-5.5,5.5v.5c0,.552,.447,1,1,1h9c.553,0,1-.448,1-1v-.5c0-3.033-2.468-5.5-5.5-5.5Z"></path><circle cx="18.5" cy="12.5" r="3.5"></circle></g>,
    },
    'serve' : {
      slug    : 'hands-heart',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M17.041.974A3.241,3.241,0,0,0,12,1.488,3.24,3.24,0,0,0,6.959.974a3.362,3.362,0,0,0,0,4.7L12,10.8l5.043-5.123A3.366,3.366,0,0,0,17.041.974Z"/><path d="M9.414,16.414h0L6,13a1.471,1.471,0,0,0-2,0,1.417,1.417,0,0,0,0,2l3,3a.5.5,0,0,1-.7.719l-.012-.012-3-3A2.417,2.417,0,0,1,3,12.652V8.5a1.5,1.5,0,0,0-3,0v6.8a2,2,0,0,0,.438,1.25L4,21v3h6V17.828A2,2,0,0,0,9.414,16.414Z"/><path d="M14.586,16.414h0L18,13a1.471,1.471,0,0,1,2,0,1.417,1.417,0,0,1,0,2l-3,3a.5.5,0,0,0,.7.719l.012-.012,3-3A2.417,2.417,0,0,0,21,12.652V8.5a1.5,1.5,0,0,1,3,0v6.8a2,2,0,0,1-.438,1.25L20,21v3H14V17.828A2,2,0,0,1,14.586,16.414Z"/></g>,
    },



    'value-1' : {
      slug    : 'position-pin',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g>
                  <path fill="var(--c-base-accent)" d="M23.517,22.608c-0.463,0.632-0.926,1.237-1.375,1.807C26.672,25.074,29,26.351,29,27 c0,0.897-4.423,3-13,3S3,27.897,3,27c0-0.649,2.328-1.926,6.858-2.584c-0.449-0.57-0.912-1.175-1.375-1.807 C4.244,23.349,1,24.783,1,27c0,3.436,7.775,5,15,5s15-1.564,15-5C31,24.783,27.756,23.349,23.517,22.608z"/>
                  <path fill="var(--c-base-head)" d="M16,0C10.593,0,5,3.944,5,10.545c0,6.219,9.855,16.666,10.275,17.107c0.188,0.199,0.45,0.311,0.725,0.311 s0.536-0.112,0.725-0.311C17.145,27.211,27,16.765,27,10.545C27,3.944,21.407,0,16,0z M16,15c-2.209,0-4-1.791-4-4s1.791-4,4-4 s4,1.791,4,4S18.209,15,16,15z"/>
                </g>,
    },
    'value-2' : {
      slug    : 'b-meeting',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g>
                  <path fill="var(--c-base-accent)" d="M31,0H14c-0.552,0-1,0.448-1,1v16.081L19,12h12c0.552,0,1-0.448,1-1V1 C32,0.448,31.552,0,31,0z"/>
                  <circle fill="var(--c-base-head)" cx="25" cy="18" r="4"/>
                  <circle fill="var(--c-base-head)" cx="7" cy="18" r="4"/>
                  <path fill="var(--c-base-head)" d="M12.525,25.433C11.421,24.779,9.565,24,7,24c-2.6,0-4.446,0.776-5.538,1.429C0.561,25.968,0,26.955,0,28.007 V31h14v-2.982C14,26.96,13.435,25.97,12.525,25.433z"/>
                  <path fill="var(--c-base-head)" d="M30.525,25.433C29.421,24.779,27.565,24,25,24c-2.6,0-4.446,0.776-5.538,1.429 C18.561,25.968,18,26.955,18,28.007V31h14v-2.982C32,26.96,31.435,25.97,30.525,25.433z"/>
                </g>,
    },
    'value-3' : {
      slug    : 'book-08',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g>
                  <path fill="var(--c-base-accent)" d="M31,6h-1v16c0,1.35-0.909,2.54-2.212,2.895l-10.999,3C16.532,27.964,16.267,28,16,28 s-0.532-0.036-0.789-0.105l-11-3C2.909,24.54,2,23.35,2,22V6H1C0.447,6,0,6.448,0,7v22c0,0.552,0.447,1,1,1h30c0.553,0,1-0.448,1-1 V7C32,6.448,31.553,6,31,6z"/>
                  <path fill="var(--c-base-head)" d="M15,4.691L5.263,2.035c-0.298-0.08-0.621-0.019-0.87,0.17C4.146,2.395,4,2.688,4,3v19 c0,0.451,0.302,0.846,0.737,0.965L15,25.764V4.691z"/>
                  <path fill="var(--c-base-head)" d="M27.607,2.205c-0.249-0.189-0.571-0.25-0.87-0.17L17,4.691v21.073l10.263-2.799 C27.698,22.846,28,22.451,28,22V3C28,2.688,27.854,2.395,27.607,2.205z"/>
                </g>,
    },
    'value-4' : {
      slug    : 'steps',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g>
                  <path fill="var(--c-base-head)" d="M28.9,2.9C28,1.2,26.5,0.2,24.7,0c-3.6-0.3-6.2,2.7-6.6,7.5c-0.1,1.8,0.2,3.6,0.6,5.4 c0.4,1.7,0.7,3.3,0.6,4.7c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.3,0.7,0.3l7,0.6c0,0,0.1,0,0.1,0c0.4,0,0.8-0.3,0.9-0.7 c0.1-0.2,1.4-4.2,1.8-9.1C30.2,6.9,29.8,4.6,28.9,2.9z"/>
                  <path fill="var(--c-base-accent)" d="M27,21.3l-7-0.6c-0.6,0-1,0.4-1.1,0.9l-0.1,1.5c-0.1,1.2,0.3,2.4,1.1,3.3 c0.8,0.9,1.9,1.5,3.1,1.6c0.1,0,0.2,0,0.4,0c2.3,0,4.3-1.8,4.5-4.1l0.1-1.5C28,21.8,27.6,21.3,27,21.3z"/>
                  <path fill="var(--c-base-head)" d="M7.3,4C5.5,4.2,4,5.2,3.1,6.9c-0.9,1.7-1.2,3.9-1,6.6c0.4,4.9,1.7,8.9,1.8,9.1C4,23,4.4,23.3,4.8,23.3 c0,0,0.1,0,0.1,0l7-0.6c0.6,0,1-0.5,0.9-1.1c-0.1-1.4,0.2-3,0.6-4.7c0.4-1.8,0.8-3.7,0.6-5.4C13.5,6.7,10.9,3.7,7.3,4z"/>
                  <path fill="var(--c-base-accent)" d="M12.8,24.9c-0.2-0.2-0.5-0.3-0.7-0.2l-7,0.6c-0.6,0-1,0.5-0.9,1.1l0.1,1.5 C4.5,30.2,6.4,32,8.7,32c0.1,0,0.3,0,0.4,0c1.2-0.1,2.3-0.7,3.1-1.6c0.8-0.9,1.1-2.1,1-3.3l-0.1-1.5C13.1,25.3,13,25.1,12.8,24.9z"/>
                </g>,
    },
    'value-5' : {
      slug    : 'photo-editor',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g>
                  <circle fill="var(--c-base-accent)" cx="16" cy="10" r="9"/>
                  <path fill="var(--c-base-head)" d="M16,21c-5.107,0-9.4-3.504-10.633-8.23C2.208,14.167,0,17.323,0,21c0,4.971,4.029,9,9,9c4.971,0,9-4.029,9-9 c0-0.064-0.008-0.126-0.01-0.19C17.344,20.929,16.68,21,16,21z"/>
                  <path fill="var(--c-base-head)" d="M26.633,12.77c-0.895,3.431-3.402,6.21-6.667,7.479C19.983,20.497,20,20.747,20,21 c0,2.685-0.97,5.146-2.574,7.058C18.96,29.27,20.893,30,23,30c4.971,0,9-4.029,9-9C32,17.323,29.792,14.167,26.633,12.77z"/>
                </g>,
    },
    'value-6' : {
      slug    : 'multiple-11',
      width   : '32',
      height  : '32',
      viewbox : '0 0 32 32',
      markup  : <g>
                  <circle fill="var(--c-base-accent)" cx="6" cy="6" r="3"/>
                  <path fill="var(--c-base-accent)" d="M7,25V15c0-1.479,0.464-2.85,1.25-3.982C8.167,11.013,8.084,11,8,11H4c-2.206,0-4,1.794-4,4 v6h2v7h7v-3H7z"/>
                  <circle fill="var(--c-base-accent)" cx="26" cy="6" r="3"/>
                  <path fill="var(--c-base-accent)" d="M25,25V15c0-1.479-0.464-2.85-1.25-3.982C23.833,11.013,23.916,11,24,11h4 c2.206,0,4,1.794,4,4v6h-2v7h-7v-3H25z"/>
                  <path fill="var(--c-base-head)" d="M18,10h-4c-2.757,0-5,2.243-5,5v8h3v8h8v-8h3v-8C23,12.243,20.757,10,18,10z"/>
                  <circle fill="var(--c-base-head)" cx="16" cy="4" r="4"/>
                </g>,
    },

  }

  return (
    <svg
      className = {`hcc-svg is-${type}`}
      width     = {`${data[type].width}`}
      height    = {`${data[type].height}`}
      viewBox   = {`${data[type].viewbox}`}
      xmlns     = "http://www.w3.org/2000/svg"
      {...props}
    >
      {data[type].markup}
    </svg>
  )

}

Svg.defaultProps = {
  type : 'worship',
}

Svg.propTypes = {
  type : PropTypes.string,
}

export default Svg
